
import { Vue, Component } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import Header from '@/components/Header/Header.vue';
import CourseSection from '@/components/Course/CourseSection.vue';
import CourseCard from '@/components/Course/CourseCard.vue';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';
import Footer from '@/components/Footer/Footer.vue';

import { UserModel } from '@/models';

@Component({
  name: 'Courses',
  components: {
    Header,
    CourseSection,
    CourseCard,
    ButtonDefault,
    Footer,
  },
  computed: {
    ...mapGetters('user', {
      getUserCourses: 'getUserCourses',
      getUserCourseId: 'getUserCourseId',
    }),
  },
  methods: {
    ...mapActions('user', {
      updateUserInfo: 'updateUserInfo',
      getUserInfo: 'getUserInfo',
    }),
  },
})
export default class Courses extends Vue {
  private updateUserInfo: () => Promise<UserModel>;

  mounted() {
    this.updateUserInfo();
  }
}
