import { render, staticRenderFns } from "./CourseSection.vue?vue&type=template&id=5ecb6e58&scoped=true"
import script from "./CourseSection.vue?vue&type=script&lang=ts"
export * from "./CourseSection.vue?vue&type=script&lang=ts"
import style0 from "./CourseSection.vue?vue&type=style&index=0&id=5ecb6e58&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ecb6e58",
  null
  
)

export default component.exports